<template>
  <section class="w1314 mh100vh">
    <div class="snavi"><img class="mr10 ml10" src="@/assets/img/snavhomeicon.png"><img src="@/assets/img/snavicon.png"><span class="ml10 b">출석부</span></div>
    <h2 class="subBanner"><img src="@/assets/img/attendendance.png" alt=""></h2>

    <div class="calendarwrap">
      <ul class="pnmonth">
        <li><a class="preMon" @click="moveMonth('prev')"><img src="@/assets/img/lefticon.png"></a></li>
        <li class="year_mon">{{currentYear}}년 {{currentMonth}}월</li>
        <li><a class="nextMon" @click="moveMonth('next')"><img src="@/assets/img/righticon.png"></a></li>
      </ul>

      <div class="calendarin">
        <ul class="weeks">
          <li v-for="(weekName, index) in weekNames" v-bind:key="index" :class="{'rdc': index === 0}">
            {{weekName}}
          </li>
        </ul>
        <table class="calendar">
<!--          <tbody>-->
<!--          <td></td><td></td><td class="date">1</td><td class="date">2</td><td class="date">3</td><td class="date">4</td><td class="date">5</td><tr></tr><td class="date">6</td><td class="date">7</td><td class="date colToday">8</td><td class="date">9</td><td class="date">10</td><td class="date">11</td><td class="date">12</td><tr></tr><td class="date">13</td><td class="date">14</td><td class="date">15</td><td class="date">16</td><td class="date">17</td><td class="date">18</td><td class="date">19</td><tr></tr><td class="date">20</td><td class="date">21</td><td class="date">22</td><td class="date">23</td><td class="date">24</td><td class="date">25</td><td class="date">26</td><tr></tr><td class="date">27</td><td class="date">28</td><td></td><td></td><td></td><td></td><td></td></tbody>-->
          <tbody>
          <tr v-for="(row, index) in currentCalendarMatrix" :key="index">
            <td v-for="(day, index2) in row" :key="index2" :class="{'colToday': isToday(currentYear, currentMonth, day), 'date': isCheck(currentYear, currentMonth, day)}">
              {{day}}
            </td>
          </tr>
          </tbody>
        </table>
        <p class="calendarday">{{currentYear}}년 {{currentMonth}}월은 총 <span class="bl">{{attenList.length}}일</span> 출석 하였습니다.</p>
      </div>
    </div>

    <div class="boardbtn pt60"><a class="blsbtn" @click="setAtten">출석체크하기</a></div>

  </section>
</template>

<script>
import { getAttenList, setAttenToday } from '@/api/attendance'
import { getDateStr } from '@/libs/utils'

export default {
  name: 'attendendance',
  data () {
    return {
      weekNames: ['일', '월', '화', '수', '목', '금', '토'],
      rootYear: 1904,
      rootDayOfWeekIndex: 4, // 2000년 1월 1일은 토요일
      currentYear: new Date().getFullYear(),
      currentMonth: new Date().getMonth() + 1,
      currentDay: new Date().getDate(),
      currentMonthStartWeekIndex: null,
      currentCalendarMatrix: [],
      endOfDay: null,
      memoDatas: [],
      attenList: []
    }
  },
  mounted () {
    this.init()
  },
  created () {
    this.loadList()
  },
  methods: {
    setAtten () {
      const todayStr = getDateStr(new Date())
      let isCheck = false
      this.attenList.forEach(item => {
        if (item) {
          const value = item.dt
          if (value === todayStr) {
            isCheck = true
          }
        }
      })
      if (!isCheck) {
        setAttenToday({}).then(response => {
          this.onCheck('출석 체크가 완료되었습니다.')
          this.loadList()
        })
      } else {
        this.onCheck('이미 출석체크를 진행하였습니다.')
      }
    },
    loadList () {
      const yStr = this.currentYear.toString()
      let mStr = this.currentMonth.toString()
      if (this.currentMonth < 10) {
        mStr = '0' + mStr
      }
      const param = {
        yyyymm: yStr + mStr
      }
      getAttenList(param).then(response => {
        const result = response.data
        if (result.resultCode === '0') {
          const data = result.data
          const list = data.list
          this.attenList = list
        }
      })
    },
    moveMonth (type) {
      if (type === 'prev') {
        if (this.currentMonth === 1) {
          this.currentMonth = 12
          this.currentYear--
        } else {
          this.currentMonth--
        }
      } else {
        if (this.currentMonth === 12) {
          this.currentMonth = 1
          this.currentYear++
        } else {
          this.currentMonth++
        }
      }
      this.init()
      this.loadList()
    },
    init: function () {
      this.currentMonthStartWeekIndex = this.getStartWeek(this.currentYear, this.currentMonth) + 1
      this.endOfDay = this.getEndOfDay(this.currentYear, this.currentMonth)
      this.initCalendar()
    },
    initCalendar: function () {
      this.currentCalendarMatrix = []
      let day = 1
      for (let i = 0; i < 6; i++) {
        const calendarRow = []
        for (let j = 0; j < 7; j++) {
          if (i === 0 && j < this.currentMonthStartWeekIndex) {
            calendarRow.push('')
          } else if (day <= this.endOfDay) {
            calendarRow.push(day)
            day++
          } else {
            calendarRow.push('')
          }
        }
        let emptyCount = 0
        calendarRow.forEach(item => {
          if (!item) {
            emptyCount++
          }
        })
        if (emptyCount !== calendarRow.length) {
          this.currentCalendarMatrix.push(calendarRow)
        }
      }
    },
    getEndOfDay: function (year, month) {
      switch (month) {
        case 1:
        case 3:
        case 5:
        case 7:
        case 8:
        case 10:
        case 12:
          return 31
        case 4:
        case 6:
        case 9:
        case 11:
          return 30
        case 2:
          // eslint-disable-next-line no-mixed-operators
          if ((year % 4 === 0) && (year % 100 !== 0) || (year % 400 === 0)) {
            return 29
          } else {
            return 28
          }
        default:

          return 0
      }
    },
    getStartWeek: function (targetYear, targetMonth) {
      let year = this.rootYear
      let month = 1
      let sumOfDay = this.rootDayOfWeekIndex
      while (true) {
        if (targetYear > year) {
          for (let i = 0; i < 12; i++) {
            sumOfDay += this.getEndOfDay(year, month + i)
          }
          year++
        } else if (targetYear === year) {
          if (targetMonth > month) {
            sumOfDay += this.getEndOfDay(year, month)
            month++
          } else if (targetMonth === month) {
            return (sumOfDay) % 7
          }
        }
      }
    },
    isToday: function (year, month, day) {
      const date = new Date()
      return year === date.getFullYear() && month === date.getMonth() + 1 && day === date.getDate()
    },
    isCheck (year, month, day) {
      const yStr = year.toString()
      let mStr = month.toString()
      if (month < 10) {
        mStr = '0' + mStr
      }
      let dStr = day.toString()
      if (day < 10) {
        dStr = '0' + dStr
      }

      const dateStr = `${yStr}-${mStr}-${dStr}`
      let result = false
      this.attenList.forEach(item => {
        if (item.dt === dateStr) {
          result = true
        }
      })

      return result
    }
  }
}
</script>
<!--script>
    $(function(){
        var today = new Date();
        var date = new Date();

        $(".preMon").click(function() { // 이전달
            $(".calendar > tbody > td").remove();
            $(".calendar > tbody > tr").remove();
            today = new Date ( today.getFullYear(), today.getMonth()-1, today.getDate());
            buildCalendar();
        })

        $(".nextMon").click(function(){ //다음달
            $(".calendar > tbody > td").remove();
            $(".calendar > tbody > tr").remove();
            today = new Date ( today.getFullYear(), today.getMonth()+1, today.getDate());
            buildCalendar();
        })

        function buildCalendar() {

            nowYear = today.getFullYear();
            nowMonth = today.getMonth();
            firstDate = new Date(nowYear,nowMonth,1).getDate();
            firstDay = new Date(nowYear,nowMonth,1).getDay(); //1st의 요일
            lastDate = new Date(nowYear,nowMonth+1,0).getDate();

            if((nowYear%4===0 && nowYear % 100 !==0) || nowYear%400===0) { //윤년 적용
                lastDate[1]=29;
            }

            $(".year_mon").text(nowYear+"년 "+(nowMonth+1)+"월");

            for (i=0; i<firstDay; i++) { //첫번째 줄 빈칸
                $(".calendar tbody:last").append("<td></td>");
            }
            for (i=1; i <=lastDate; i++){ // 날짜 채우기
                plusDate = new Date(nowYear,nowMonth,i).getDay();
                if (plusDate==0) {
                    $(".calendar tbody:last").append("<tr></tr>");
                }
                $(".calendar tbody:last").append("<td class='date'>"+ i +"</td>");
            }
            if($(".calendar > tbody > td").length%7!=0) { //마지막 줄 빈칸
                for(i=1; i<= $(".calendar > tbody > td").length%7; i++) {
                    $(".calendar tbody:last").append("<td></td>");
                }
            }
            $(".date").each(function(index){ // 오늘 날짜 표시
                if(nowYear==date.getFullYear() && nowMonth==date.getMonth() && $(".date").eq(index).text()==date.getDate()) {
                    $(".date").eq(index).addClass('colToday');
                }
            })
        }
        buildCalendar();

    })
</script 달력 script -->
<style scoped>
.pnmonth {display: flex;justify-content: center;}
.weeks {width: 100%;display: flex;justify-content: space-around;border: 1px solid #ddd;border-bottom: 0;text-align: center;align-items: center;padding: 10px 0;font-weight: bold;box-sizing: border-box;background: #fff;font-size: 21px;}
.year_mon {color: #0056a6;font-size: 25px;font-weight: bold;}
.pnmonth a img {height: 20px;vertical-align: middle;}
.preMon {margin-right: 30px;}
.nextMon {margin-left: 30px;}
.calendar td.colToday{color: #e35c54;}
.calendar td.on {background: url("~@/assets/img/stampRed.png") no-repeat !important;background-size: contain !important;background-position: center !important;}
.calendarin {box-shadow: 1px 0px 5px 1px #f0f0f0;border: 1px solid #e9e9e9;border-radius: 20px;padding: 50px 50px 25px;margin-top: 20px;background: #fafafa;}
.calendar {width: 100%;border :1px solid #ddd;background: #fff;font-weight: bold;}
.calendar tr, .calendar td {border :1px solid #ddd;}
.calendar td {height: 130px;padding: 10px 0 0 10px;}
.calendar td.date {background: url("~@/assets/img/stampGray.png") no-repeat;background-size: 120px;background-position: center;font-size: 21px;}
.calendarday {padding-top: 25px;text-align: center;font-weight:normal;font-size: 20px;}
.calendarday span {font-weight: bold;}
</style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
